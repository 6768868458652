$( document ).ready(function() {

    // When you click on the +/- button it expands
    $('.btn-expander').on('click keypress', function() {
        if($(this).hasClass('collapsed')) {
            $(this).removeClass('collapsed');
            $($(this).attr('data-target')).slideDown('slow', function(){
                $($(this).attr('data-target')).removeClass('collapse');
            });
        } else {
            $(this).addClass('collapsed');
            $($(this).attr('data-target')).slideUp('slow', function(){
                $($(this).attr('data-target')).addClass('collapse');
            });
        }
    });

    //Image carousel play/pause
    $('.play-pause').on('click', function(a){
        a.preventDefault();
        if( $(this).hasClass('pause') ){
            $(this).closest('.carousel').carousel('cycle');
        }else{
            $(this).closest('.carousel').carousel('pause'); 
        }
        $(this).toggleClass('pause');
    }); 

    //Step progress back buttons
	$('.prev-step').on('click', function() {
        $(this).closest('.resp-tabs-list').prev('li').trigger('click');
    });

    //Step progress forward buttons
	$('.next-step').on('click', function(){
        $(this).closest('.resp-tabs-list').next('li').trigger('click');
    });

});