$( document ).ready(function() {
    let offsetBreakPoint = 960;
    if ($('.wf-offset-block').length && $(window).width() >= offsetBreakPoint) {
        
        $('.wf-offset-block').each(function() {

            // Height of the inner class
            let height = parseInt($(this).find('.inner').css('height'));

            //Previous block adjustments
            let prev_block = $(this).prev('.wf-block');
            let prev_pad = parseInt($(prev_block).css('padding-bottom')) + parseInt($(prev_block).css('margin-bottom')) + (height / 2); 
            if ($(prev_block).hasClass('wf-block-carousel')) {
                prev_pad = 0;
            }

            //Next block adjustments
            let next_block = $(this).next('.wf-block');
            let next_pad = parseInt($(next_block).css('padding-top')) + parseInt($(next_block).css('margin-top')) + (height / 2);
            if ($(next_block).hasClass('wf-block-carousel')) {
                next_pad = 0;
            }
            
            //Apply the paddings to the next and prev blocks
            $(prev_block).css('padding-bottom', prev_pad + 'px');
            $(next_block).css('padding-top', next_pad + 'px');

            //Apply height and margin to current block
            $(this).find('.inner').css('margin-top', '-' + (height / 2) + 'px');

        });

    }
});    