jQuery(function() { 
    if ($('#employee-directory-content').length) {

        //Get the API employee URL based on this installation's setup
        let employee_url = ''; //Default

        //This installation's url for querying
        let employee_fetch_url = document.location.protocol + '//' + document.location.hostname + '/wp-json/employees/url'; 

        //If the department is forced 
        const forceddepartment = $('#employee-directory-content').data('forcedepartment');

        //The main container of the mixer content
        const container = document.querySelector('[data-ref="employee-container"]');

        //Updated search
        let employeeInputSearch = document.querySelector('[data-ref="employee-input-search"]');
        let employeeSearchTimeout; //Timeout for the input search
        const page_limit = 10;
        let currentPage = 1;

        //Setup search url parameter
        let searchParam = '';
        //The categories for the query string when someone selects from the dropdown
        let departments = '';
        if (forceddepartment != '' && forceddepartment != '*') {
            departments += '&department=' + encodeURIComponent(forceddepartment);
        }

        //This will not change, and it will be used in a few places like the search input
        const defaultControlUrl = employee_url + '/wp-json/employees/employee_filter?per_page=' + page_limit + '&order=asc' + departments;
        
        //This will be used throughout the MixItUp section, etc.
        //Essentially, this is the current context URL (what controls the rendered data)
        //So, if I'm clicking through the pagination or filtering, this will change to meet that query
        let controlUrl = defaultControlUrl + '&page=' + currentPage + searchParam;        

        //The mixer (needs to be located AFTER the $.each from above)
        const mixer = mixitup(container, {
            debug: {
                enable: true,
                showWarnings: true
            },
            data: {
                uidKey: 'id', //This is required to be in the JSON list
            },
            render: {
                target: render
            },
            animation: {
                enable: false
            },
            selectors: {
                target: '[data-ref="employee-person"]',
            }
        });

        $.ajax({
            url: employee_fetch_url,
            async: false,
            dataType: 'json',
            success: function(data) {
                if (data != '') {
                    employee_url = data; //We have the installation's employee URL
                } else {
                    employee_url = document.location.protocol + '//' + document.location.hostname;
                }
            }
        });

        //The HTML for the individual employee card
        function render(item) {
            let is_vip = '';
            if (item.is_vip) {
                is_vip = 'is_vip';
            }
            let pro_title = item.professional_title;
            if (pro_title == '' || pro_title == null || pro_title == undefined) {
                pro_title = 'Professional Title Here';
            }
            let vip_star = '';
            if (item.meta.is_vip) {
                vip_star = '<i class="fas fa-star"></i> '
            }
            return '<div class="mix row border-bottom-yellow pb-4 pt-4 card-' + item.ID + ' ' + is_vip + '" data-ref="employee-person">' +
                img_section +
            '<div class="col-md-' + col_num + '">' +
                '<h2 class="font-sofia-bold text-uppercase"><a href="' + item.link + '">' + vip_star + item.meta.last_name + ', ' + item.meta.first_name + '</a></h2>' +
                '<p class="font-sofia-bold">' + pro_title + '</p>' +
                '<p class="mb-4">' + item.meta.department_name + '</p>' +
                '<p><a href="mailto:' + item.meta.email_address + '">' + item.meta.email_address + '</a></p>' +
                '<p><a href="tel:' + item.meta.phone_number + '">' + item.meta.phone_number + '</a></p>' +
            '</div>' +
            '</div>';
        }

        //The HTML for the individual categories lists
        function renderSelectCat(item)
        {
            return '<option value="' + encodeURIComponent(item.name) + '">' + item.name + '</option>';
        }

        function loadDepartmentsDropdowns()
        {
            $.ajax({
                url: employee_url + "/wp-json/wp/v2/department?_fields=id,link,name,slug&per_page=100",
                async: false,
                dataType: 'json',
                success: function(data) {
                    $.each(data, function(id, item) {
                        $('#department-filter-dropdown').append(  
                            $( renderSelectCat( item ) )  
                        );
                    });
                }
            });
        }

        function employees_mixer_data(url)
        {
            $('#employee-directory-content .loading-overlay').removeClass('hide');
            $.ajax({
                url: url,
                success: function (items, status, request) {
                    //Update the mixer with the new dataset
                    mixer.dataset(items).then(function(state) {
                        //Nothing right now.
                        });
                    
                    //Update the pagination
                    employee_pagination(request.getResponseHeader('x-wp-totalpages'));

                    //Lastly, get rid of the loader
                    $('#employee-directory-content .loading-overlay').addClass('hide');
                }
            });
        }

        function employee_pagination(totalPages)
        {

            let slot1, slot2, slot3, slot4, slot5;
            slot5 = totalPages;
            if (totalPages < 5) {
                slot5 = 0;
            }
            if (totalPages <= 4) {
                if (currentPage < 4) {
                    slot1 = (totalPages == 0) ? 0 : 1; 
                    slot2 = (totalPages <= 1) ? 0 : 2; 
                    slot3 = (totalPages <= 2) ? 0 : 3; 
                    slot4 = (totalPages <= 3) ? 0 : 4;
                } 
            } else {
                if (currentPage < 4) {
                    slot1 = 1; slot2 = 2; slot3 = 3; slot4 = 4;
                } else if (currentPage >= (totalPages - 4)) {
                    slot1 = totalPages - 4; 
                    slot2 = totalPages - 3; 
                    slot3 = totalPages - 2; 
                    slot4 = totalPages - 1;
                } else {
                    slot1 = currentPage - 2; 
                    slot2 = currentPage - 1; 
                    slot3 = currentPage; 
                    slot4 = currentPage + 1;
                }
            }

            let pagination_html = '<li class="page-item employee-page-item"><a class="page-link employee-page-first" data-page="1" role="button">First</a></li>' +
                '<li class="page-item employee-page-item"><a data-page="prev" class="page-link employee-mixitup-control mixitup-control-prev" role="button">Prev</a></li>';

            if (slot1 != 0) {
                pagination_html += '<li class="page-item employee-page-item"><a data-page="' + slot1 + '" class="page-link page-number employee-page-number ' + (slot1 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot1 + '</a></li>';
            }

            if (slot2 != 0) {
                pagination_html += '<li class="page-item employee-page-item"><a data-page="' + slot2 + '" class="page-link page-number employee-page-number ' + (slot2 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot2 + '</a></li>';
            }

            if (slot3 != 0) {
                pagination_html += '<li class="page-item employee-page-item"><a data-page="' + slot3 + '" class="page-link page-number employee-page-number ' + (slot3 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot3 + '</a></li>';
            }

            if (slot4 != 0) {
                pagination_html += '<li class="page-item employee-page-item"><a data-page="' + slot4 + '" class="page-link page-number employee-page-number ' + (slot4 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot4 + '</a></li>';
            }

            if (slot5 != 0) {
                pagination_html += '<li class="page-item employee-page-item"><a data-page="' + slot5 + '" class="page-link page-number employee-page-number ' + (slot5 == currentPage ? 'mixitup-control-active' : '') + '" role="button">' + slot5 + '</a></li>';
            }

            pagination_html += '<li class="page-item employee-page-item"><a data-page="next" class="page-link   employee-mixitup-control mixitup-control-next" role="button">Next</a></li>' +
                '<li class="page-item employee-page-item"><a class="page-link employee-page-last" role="button">Last</a></li>';

            $('#employees-programs-pagination').html(pagination_html);
            
            //First pagination link
            $('.employee-page-first').on('click', function() {
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=1';
                    currentPage = 1;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=1';
                    currentPage = 1;
                }
            });

            //The final page link
            $('.employee-page-last').on('click', function() {
                currentPage = totalPages;
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=' + currentPage;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=' + currentPage;
                }
            });

            //The "Next" page button
            $('.employee-mixitup-control.mixitup-control-next').on('click', function() {
                if (currentPage != totalPages) {
                    currentPage = currentPage + 1;
                }
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=' + currentPage;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=' + currentPage;
                }
            });
    
            //The the "Previous" page button
            $('.employee-mixitup-control.mixitup-control-prev').on('click', function() {
                if (currentPage != 1) {
                    currentPage = currentPage - 1;
                }
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=' + currentPage;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=' + currentPage;
                }
            });

            //Any other page number in the pagination list
            $('.employee-page-number').on('click', function() {
                currentPage = $(this).data('page');
                if ($('.metric').length) {
                    controlUrl = controlUrl + '&page=' + currentPage;
                } else {
                    controlUrl = defaultControlUrl + searchParam + '&page=' + currentPage;
                }
            });

            //Take the new control url that was edited above and set it
            $('.employee-page-item').on('click', function() {
                employees_mixer_data(controlUrl);
            });

        }

        function employeee_reset_all()
        {
            //Reset the dropdown
            $("#department-filter-dropdown").prop("selectedIndex", 0);
        }

        //Search Filter
        $(employeeInputSearch).on('keyup', function() {
            //Clear the filters
            employeee_reset_all();
            //Proceed to update the query
            clearTimeout(employeeSearchTimeout);
            employeeSearchTimeout = setTimeout(function() {
                searchParam = '&search=' + encodeURIComponent(employeeInputSearch.value);
                controlUrl = defaultControlUrl + searchParam + '&page=1';
                employees_mixer_data(controlUrl);
            }, 500); //delayed for this amount of time
        });

        $("#department-filter-dropdown").change(function() {
            if ($(this).find('option:selected').val() != '') {
                departments = '';
                departments += '&department=' + encodeURIComponent($(this).find('option:selected').text());
                controlUrl = defaultControlUrl + departments;
                employees_mixer_data(controlUrl + '&page=1');
                employeeInputSearch.value = '';
            } else {
                departments = '';
                controlUrl = defaultControlUrl + departments;
                employees_mixer_data(controlUrl + '&page=1');
                employeeInputSearch.value = '';
            }
        });

        //Init loads
        loadDepartmentsDropdowns();
        employees_mixer_data(controlUrl);

    }
});
